<template>
<div class="notice">
  <div class="head">
    <van-image :src="headImg" :error-icon="sex === 1? man : woman"></van-image>
    <p>{{name}}</p>
    <div class="introduce">
      <h3>代表风采</h3>
      <div class="content" v-html="content">
      </div>
      <h4 @click="checkDetail">查看详情 ></h4>
    </div>
  </div>
  <div class="cont">
    <h3>代表工作与活动</h3>
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList" v-if="dataList.length > 0" offset="10">
      <van-cell v-for="item in dataList" :key="item.id"  @click="toDetail(item.id)">
        <div class="item">
          <van-row>
            <van-col :span="22">
              <p>【{{item.noticeTypeStr}}】{{item.title}}</p>
            </van-col>
            <van-col :span="2">
              <van-icon name="arrow" />
            </van-col>
          </van-row>
          <van-row>
            <van-col :span="22">
              <span>{{item.createTime}}</span>
            </van-col>
          </van-row>
        </div>
      </van-cell>
    </van-list>
    <van-empty description="没有数据哦"  v-else/>
  </div>
</div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import {getImageStream} from "../../utils";

export default {
  components :{
    topBar,
    getImageStream
  },
  name: "peopleMail",
  data() {
    return {
      page: 0,
      limit: 10,
      content: '',
      name: '',
      headImg: '',
      man: require('@/assets/img/weMan.png'),
      woman: require('@/assets/img/weWoman.png'),
      id: 0,
      userId: 0,
      sex: 1,
      isLoading:false,
      loading: false,
      finished:false,
      dataList:[]
    };
  },
  created() {
    this.id = this.$route.query.id
    this.getInfo()
    this.getPeopleInfo()
    this.getDataList()
  },
  methods: {
    checkDetail () {
      this.$router.push({path: '/peopleDetail', query:{id: this.id}})
    },
    toDetail (id) {
      this.$router.push({path: '/peopleJob-info', query:{id: id}})
    },
    getInfo () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/app/userinfoauxiliary/getIntroduce'),
        method: 'post',
        params: this.$http.adornParams({
          userId: this.id
        })
      }).then(({data})=> {
        if (data.code == 0) {
          this.content = data.introduce
          this.$toast.clear()
        } else {
          console.log(data);
          this.$toast.clear()
          this.$toast.fail(data.msg);
        }
      })
    },
    getPeopleInfo () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/user/simple/info'),
        method: 'post',
        params: this.$http.adornParams({
          id: this.id
        })
      }).then(({data})=> {
        if (data.code == 0) {
          this.headImg = getImageStream(data.userInfo.headImg || 'aaaaaa')
          this.name = data.userInfo.name
          this.sex = data.userInfo.sex
          this.$toast.clear()
        } else {
          console.log(data);
          this.$toast.clear()
          this.$toast.fail(data.msg);
        }
      })
    },
    getDataList () {
      this.page++
      this.$toast.loading({duration: 0,message: '加载中...',forbidClick: true,});
      this.$http({
        url: this.$http.adornUrl('/wxapp/commonApp/notice/list'),
        method: 'post',
        params: this.$http.adornParams({
          page: this.page,
          limit: this.limit,
          isNpc: 1,
          createUserId: this.id,
          orgId: this.$orgId,
          surveyObject: -1
        })
      }).then(({data})=> {
        if (data.code == 0) {
          if (this.dataList.length == data.page.totalCount) {
            this.finished = true
          }
          this.dataList = this.dataList.concat(data.page.list)
          // 加载状态结束
          this.loading = false;
        }
        this.$toast.clear()
      },err=> {this.$toast.clear()})
      // 数据全部加载完成
    }
  }
}
</script>

<style scoped lang="scss" >
.notice {
  padding-top: 100px;
  min-height: 100vh;
  background-color: #FFFFFF !important;
  .head {
    position: relative;
    height: 294px;
    background-image: url("../../assets/img/weDeputy.png");
    background-size: 100%;
    background-repeat: no-repeat;
    ::v-deep .van-image {
      position: absolute;
      top:40%;
      left:50%;
      transform:translate(-50%,-50%);
      display: block;
      width: 104px;
      height: 104px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    ::v-deep .van-image__error {
      border-radius: 50%;
    }
    p {
      position: absolute;
      top:65%;
      left:50%;
      transform:translate(-50%,-50%);
      font-size: 30px;
      font-weight: 600;
      font-family: PingFang SC;
      color: #FFFFFF;
    }
    .introduce {
      position: absolute;
      top: 90%;
      left: 0;
      width: 100%;
      height: 360px;
      border-radius: 40px;
      background-color: #FFFFFF;
      h3 {
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #333333;
        margin-top: 50px;
        margin-left: 20px;
      }
      .content {
        display: block;
        height: 280px;
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #4A505A;
        line-height: 55px;
        margin: 0 20px;
        ::v-deep p {
          height: 280px;
          background: linear-gradient(to top,#fff 0%,#333 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          img {
            height: 100%;
          }
        }
      }
      h4 {
        position: absolute;
        top: 100%;
        right: 3%;
        z-index: 999;
        font-size: 24px;
        font-weight: 500;
        color: #4A81F5;
        //margin: -45px 10px 0 80%;
      }
    }
  }
  .cont {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    border-radius: 20px;
    background-color: #FFFFFF !important;
    h3 {
      font-size: 30px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
    }
    .van-cell {
      border: 1px solid #CFCFCF;
      padding: 25px 30px;
    }
    .item {
      .van-row:nth-child(1) .van-col:nth-child(2){
        margin-top: 10px;
        padding-left: 22px;
        padding-top: 25px;
      }
      .van-row:nth-child(2) .van-col:nth-child(2){
        padding-left: 22px;
      }
      p {
        font-size: 30px;
        color: #272727;
        margin: 10px 0;
      }
      span {
        font-size: 26px;
        padding-left: 20px;
      }
    }
  }
}
</style>
